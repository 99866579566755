<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "FourEasyStepsToAWritingPlan",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FourEasyStepsToAWritingPlan">
      <SuawParagraph
        text="Have you ever put together a writing plan? In case that sounds strange, consider this – how often do we hear that a bit of planning and organization can make us more effective? We do it at work in managing our tasks and our priorities, menu planning our dinners to make our evenings smoother, our calendars so we can go to the gym and find time for that happy hour with our friends – we plan everything else, why not our writing?"
      />
      <SuawHeading level="3" content="Making Your Writing Plan" />
      <SuawParagraph text="Here are four super easy steps to help you maximize your writing time and keep you on track to accomplish your writing goals." />
      <SuawHeading level="3" content="Step 1: What do you want to accomplish?" />
      <SuawParagraph text="Is it to edit your first draft, finish that novel, or submit your work to 50 places? Write your goal out in a succinct and specific statement." />
      <SuawParagraph
        text="Your goal goes at the top of your writing plan so you know where you’re headed. You’ve defined what success looks like. Now you can visualize it in your mind and it will start to feel more real."
      />
      <SuawHeading level="3" content="Step 2: What do you need to make it happen?" />
      <SuawParagraph
        text="Ask yourself, do you have all the tools you need? How is your writing space, is it working for you? Do you need an accountability buddy and have you made writing a habit yet? Have you researched the places you want to submit your work to? Have you received all of your feedback to start editing?"
      />
      <SuawParagraph text="<strong>In essence, could you start today?</strong>" />
      <SuawParagraph
        text="If not, make a list of what you still need to get started. Make them actionable, what are the specific steps you need to take. All of this goes into your writing plan so that you ensure you have what you need to be successful."
      />
      <SuawParagraph
        text="A few we suggest: lock down a place to write and a consistent day/time to write, build in some sort of accountability to your practice. It always helps to clue someone else in on your goals."
      />
      <SuawHeading level="3" content="Step 3: When is it going to be complete?" />
      <SuawParagraph text="Or put another way, how much time do you need to accomplish your goal?" />
      <SuawParagraph text="This step asks you to evaluate how much work is involved and there may be some unknowns. So, let’s do some educated guesswork." />
      <SuawParagraph
        text="Assume you can bang out 1,000 words an hour. Then depending on how long your writing project needs to be, do the math to figure out how many hours it will take. That’s how many 1-hour writing sessions you’ll need to get your first draft."
      />
      <SuawParagraph
        text="What about editing? Assuming it’s 2 pages/hour for a first draft. On a second draft, assume 5 pages/hour. These aren’t set numbers for everyone, but it’s a good average to work with when in the planning phase."
      />
      <SuawParagraph
        text="Now that you have a benchmark of the time it will take, decide how many sessions you can fit in your week. Then you’ll know how many weeks it will take."
      />
      <SuawParagraph
        text="Another method – start from the date you want to be finished, say Dec 31st, 2018, there are 46 weeks. If you do two sessions a week, you can write a novel with 92,000 words."
      />
      <SuawParagraph text="Write it in your writing plan in a single statement; for example “I need 3 writing sessions a week for the next six months.”" />
      <SuawParagraph
        text="Make sure your schedule is reasonable with your other commitments (work, family, friends, etc). Here, it is important to be realistic. Writing any big piece is a marathon, so pick a pace you can keep for months. This is your commitment to yourself, it should feel not only doable but inspire you."
      />
      <SuawHeading level="3" content="Step 4: Keys to Success" />
      <SuawParagraph
        text="You wrote down your goal. You know how much time each week you want to work on it, and hopefully, you have everything you need to get started. What’s left?"
      />
      <SuawParagraph
        text="<ul><li>Tell someone your goal and your writing plan! Pick someone who will cheer you on, who will celebrate the small wins with you. Clue them in when you make it to a writing session when you’ve written your first few chapters. This should be someone who will encourage you when you miss that writing session or when you feel stuck and want to give up. Also, you can always chime in on our Facebook page!</li><li>Write your goal on a piece of paper and put it somewhere you’ll see when you’re working or when you get up in the morning.</li><li>Put your writing sessions in your calendar for the entire year.</li><li>Pick a reward. It doesn’t matter what it is, maybe it’s a great travel mug or a leather-bound journal you’ve been wanting, but put something at the finish line. If you really like this idea, plan rewards for yourself at every milestone as well.</li></ul>"
      />
      <SuawParagraph
        text="Putting together a writing plan is taking time to think about your goal and how you will accomplish it. If you find you need more detail, break down your tasks into milestones. Refer back to your writing plan and update it as you write. It will serve as a tool to help keep you organized and on track."
      />
      <SuawParagraph text="If you have any questions, please leave a comment or reach out to us on Facebook. We love hearing from our community of writers." />
    </ArticleFrame>
  </SuawMainContent>
</template>
